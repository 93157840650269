import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import "bootstrap/scss/bootstrap.scss";
//is user enter
import useList from "../components/isAuth/isAuth";
import AuthPage from "./AuthPage/index.js";
// ========= Plugins CSS START =========
import "../public/css/plugins/feature.css";
import "../public/css/plugins/animation.css";
import "../node_modules/sal.js/dist/sal.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-tooltip/dist/react-tooltip.css";
import "../public/css/App.css";
import "../public/css/MessageItem.css";
import "primereact/resources/themes/saga-blue/theme.css"; // PrimeReact tema CSS
import "primereact/resources/primereact.min.css"; // PrimeReact temel CSS
import "primeicons/primeicons.css";
// ========= Plugins CSS END =========
import Context from "@/context/Context"; 
import { ThemeProvider } from '@/context/ThemeContext';
import "../public/scss/style.scss";
import Loading from "@/components/Loading/Loading";
import { configureStore } from "@reduxjs/toolkit";
import feedbackReducer from "@/slices/feedbackSlice";
import spinnerSlice from "@/slices/spinnerSlice";
import spinnerSliceBot from "@/slices/spinnerSliceBot";
import { messagesReducer } from "@/slices/messagesReducer";
import { Provider } from "react-redux";
import Home from "./Home/index.js";

import * as serviceWorkerRegistration from '../utils/serviceWorkerRegistrations';

//multilangues
import enMessages from "../locales/en.json";
import trMessages from "../locales/tr.json";

// Cookie Context'i ekliyoruz
import { CookieProvider } from "@/context/CookieContext";

const messages = {
  en: enMessages,
  tr: trMessages,
};

export default function App({ Component, pageProps }) {
  const [darkMode, setDarkMode] = useState(false);

  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(() => {
    if (typeof window !== "undefined") {
      const savedLanguage = localStorage.getItem("language");
      return savedLanguage || "en";
    }
    return "en";
  });

  const [authenticated, setAuthenticated] = useState(false);
  const [login, setLogin] = useState(false);
  const router = useRouter();
  useEffect(() => {
    serviceWorkerRegistration.register();
    if (router.locale === "tr" || router.locale === "en") {
      setLanguage(router.locale);
      localStorage.setItem("language", router.locale); // Locale'i localStorage'a kaydet
    } 
    require("bootstrap/dist/js/bootstrap.bundle.min.js");

    const handleStart = (url) => url !== router.asPath && setLoading(true);
    const handleComplete = () => setLoading(false);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router]);

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);

    if (typeof window !== "undefined") {
      localStorage.setItem("language", newLanguage);
      document.cookie = `NEXT_LOCALE=${newLanguage}; path=/; max-age=31536000`; // 1 yıl geçerli
      
      const currentPath = window.location.pathname;
      console.log(currentPath)
      // Mevcut yolun başındaki dil kodunu kaldır
      const newPath = currentPath.replace(/^\/(en|tr)/, ''); // Başındaki dil kodunu kaldır
      console.log(newPath)
      if (newLanguage === 'en') {
        // EN seçilirse sadece temiz path'i kullan
        window.location.href = newPath || '/';
    } else if (newLanguage === 'tr') {
        // TR seçilirse başına /tr ekle
        window.location.href = `/tr${newPath}`;
    }
    }
  
  };

     
  let list = useList();
 
  const store = configureStore({
    reducer: {
      feedback: feedbackReducer,
      messages: messagesReducer,
      spinner: spinnerSlice,
      spinnerBot: spinnerSliceBot,
    },
  });

  if (list.length === 0) {
    return <Loading />;
  }

  if (!list[1] && (router.pathname === "/chat" || router.pathname === "/setup")) {
    return (
      <Context>
      <Provider store={store}>
        <IntlProvider locale={language} messages={messages[language]}>
          <CookieProvider>
          <ThemeProvider>
            {loading ? <Loading /> : <Home handleLanguageChange={handleLanguageChange} />}
            </ThemeProvider>
          </CookieProvider>
        </IntlProvider>
      </Provider>
      </Context>
    );
  }

  return (
    <Context>
    <Provider store={store}>
      <IntlProvider locale={language} messages={messages[language]}>
        <CookieProvider>
        <ThemeProvider>
          {loading ? (
            <Loading />
          ) : (
            <Component {...pageProps} handleLanguageChange={handleLanguageChange} />
          )}
            </ThemeProvider>
        </CookieProvider>
      </IntlProvider>
    </Provider>
    </Context>
  );
}
