import React, { useEffect, useRef,useState } from "react";
import { FormattedMessage ,useIntl  } from 'react-intl';
import Link from 'next/link';
import llms from "../../public/images/llm-home/nova_explosion.png";
import research_3 from "../../public/images/llm-home/research-3.png";
import oldcar from "../../public/images/llm-home/oldcar.png";

import Image from "next/image";


import useList from '@/components/isAuth/isAuth';


const FAQ = () => {
    const list = useList();
    const intl = useIntl();
    const userID = list[0];
    const [activeTab, setActiveTab] = useState('create');

    // Her sekme için resim tanımlama
    const tabImages = {
        analyze: llms,
        create: research_3,
        compare: oldcar
    };

    const tabContent = {
      analyze: {
          title: intl.formatMessage({ id: "faq.analyze.title" }),
          subtitle: intl.formatMessage({ id: "faq.analyze.subtitle" }),
          options: [
              intl.formatMessage({ id: "faq.analyze.option1" }),
              intl.formatMessage({ id: "faq.analyze.option2" }),
              intl.formatMessage({ id: "faq.analyze.option3" }),
              intl.formatMessage({ id: "faq.analyze.option4" }),
          ]
      },
      create: {
          title: intl.formatMessage({ id: "faq.create.title" }),
          subtitle: intl.formatMessage({ id: "faq.create.subtitle" }),
          options: [
              intl.formatMessage({ id: "faq.create.option1" }),
              intl.formatMessage({ id: "faq.create.option2" }),
              intl.formatMessage({ id: "faq.create.option3" }),
              intl.formatMessage({ id: "faq.create.option4" }),
          ]
      },
      compare: {
          title: intl.formatMessage({ id: "faq.compare.title" }),
          subtitle: intl.formatMessage({ id: "faq.compare.subtitle" }),
          options: [
              intl.formatMessage({ id: "faq.compare.option1" }),
              intl.formatMessage({ id: "faq.compare.option2" }),
              intl.formatMessage({ id: "faq.compare.option3" }),
              intl.formatMessage({ id: "faq.compare.option4" }),
          ]
      }
  };


    return (
        <>
               <div className="container1">
            <h1>
                <FormattedMessage id="faq.title" />
            </h1>
            <p className="subtitle">
                <FormattedMessage id="faq.subtitle" />
            </p>
            
            <div className="tabs">
                <button 
                    className={`tab ${activeTab === 'analyze' ? 'active' : ''}`}
                    onClick={() => setActiveTab('analyze')}
                >
                    <FormattedMessage id="faq.tab.analyze" />
                </button>
                <button 
                    className={`tab ${activeTab === 'create' ? 'active' : ''}`}
                    onClick={() => setActiveTab('create')}
                >
                    <FormattedMessage id="faq.tab.create" />
                </button>
                <button 
                    className={`tab ${activeTab === 'compare' ? 'active' : ''}`}
                    onClick={() => setActiveTab('compare')}
                >
                    <FormattedMessage id="faq.tab.compare" />
                </button>
            </div>

            <div className="content-wrapper">
                    <div className="image-section">
                        <Image 
                            src={tabImages[activeTab]} 
                            alt={`${activeTab} Visualization`} 
                            className="circular-image" 
                        />
                        <button className="create-button">
                            <FormattedMessage id="faq.button.create" />
                        </button>
                    </div>

                    <div className="options-section">
    <h2>{tabContent[activeTab].title}</h2>
    <p className="section-subtitle">
        {tabContent[activeTab].subtitle}
    </p>
    <div className="cards-grid">
        {tabContent[activeTab].options.map((option, index) => (
            <div className="card" key={index}>
                <i className={`feather-${
                    index === 0 ? 'bar-chart-2' : 
                    index === 1 ? 'file-text' :
                    index === 2 ? 'camera' :
                    index === 3 ? 'activity' : 'star'
                }`}></i>
                <div className="card-content">
                    <h3>{option}</h3>
                    <p>{tabContent[activeTab].subtitle}</p>
                </div>
            </div>
        ))}
    </div>
</div>


            </div>
        </div>

            <style jsx>{`
                .container1 {
        max-width: 1200px;
        margin: 0 auto;
        padding: 2rem;
        margin-top: 5rem;
    }

    h1 {
    text-align: center;
    font-size:4rem;  /* Increased from 2.5rem */
    margin-bottom: 1rem;
    font-weight: 700;
}

.subtitle {
    text-align: center;
    color: #666;
    margin-bottom: 2rem;
    font-size: 1.4rem;  /* Increased from 1.1rem */
}

.tab {
    padding: 1rem 2rem;
    border: none;
    border-radius: 20px;
    background: #f5f5f5;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1.4rem; /* Decreased from 2rem */
    margin: 2rem 0; /* Added margin for spacing */
}

.tabs {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 3rem 0; /* Increased from 2rem */
    padding: 1rem 0; /* Added padding for extra spacing */
}


h2 {
    font-size: 1.5rem;  /* Increased from 1.2rem */
    color: #4758FF;
    margin-bottom: 0.5rem;
}

h3 {
    font-size: 3rem;  /* Increased from 1.8rem */
    margin-bottom: 1rem;
    font-weight: 700;
}

.option-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1.5rem;
    background: #f5f5f5;
    border-radius: 12px;
    text-decoration: none;
    color: inherit;
    transition: all 0.3s ease;
    font-size: 1.6rem;
    gap: 1rem; /* Add gap between text and arrow */
}

.arrow {
    margin-left: auto; /* Push arrow to the right */
    padding-left: 1rem; /* Add padding to separate from text */
    font-size: 1.3rem;
    font-weight: bold;
}

.option-text {
    flex: 1;
    font-weight: 500;
    padding-right: 1rem; /* Add padding to separate from arrow */
}


/* Updated responsive styles */
@media (max-width: 768px) {
    h1 {
        font-size: 2.8rem;
    }

    .subtitle {
        font-size: 1.3rem;
    }

    .tab {
        font-size: 1.2rem;
    }

    .option-item {
        font-size: 1.3rem;
    }

    h3 {
        font-size: 2rem;
    }
}

    .subtitle {
        text-align: center;
        color: #666;
        margin-bottom: 2rem;
        font-size: 2rem;
    }

 


    .tab:hover {
        background: #e0e0e0;
    }

    .tab.active {
        background: #4758FF;
        color: white;
    }

 
.content-wrapper {
    display: flex;
    gap: 4rem;
}


.image-section {
    flex: 0 0 500px;
    position: relative;
    border-radius: 25px;
    overflow: hidden;
    height: 100%;
    transform: translateZ(0); /* Force hardware acceleration */
}

.circular-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Resmi kapsayıcıya sığdır */
    border-radius: 25px;
    transition: transform 0.3s ease;
}

    .circular-image:hover {
        transform: scale(1.02);
    }

    .create-button {
        position: absolute;
        bottom: 20px;
        left: 20px;
        padding: 0.8rem 1.5rem;
        background: rgba(255, 255, 255, 0.9);
        border: none;
        border-radius: 25px;
        cursor: pointer;
        transition: all 0.3s ease;
        font-size: 1.1rem;  /* Added font-size */
    }

    .create-button:hover {
        background: rgba(255, 255, 255, 1);
        transform: translateY(-2px);
    }

    .options-section {
    flex: 0 0 600px; /* Sabit genişlik */
    padding-left: 10px;
    height: 100%; /* content-wrapper'ın yüksekliğine göre */
    overflow-y: none; /* İçerik fazlaysa scroll */
}
.card {
    background: #fff;
    border-radius: 12px;
    padding: 1.5rem;
    display: flex;
    align-items: center; /* Üste hizala */
    gap: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    flex-direction: row;
    justify-content: center;
    transition: all 0.3s ease;
    height: 100px; /* Sabit yükseklik */
}
    .options-list {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        padding-left:25px;

        flex:1;
        width:100%;
    }

 


    .option-item:hover {
        background: #eee;
        transform: translateX(5px);
    }

 
.cards-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    margin-top: 2rem;
}


.card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.card i {
    font-size: 2.5rem; /* 1.5rem'den 2.5rem'e yükseltildi */
    color: #4758FF;
}
.card-content {
    text-align: left; /* Yazıları sola yasla */
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex: 1;
}


.card-content h3 {
    font-size: 1.6rem; /* Başlık boyutunu artır */
    margin-bottom: 0.5rem;
    text-align: left;
    margin: 0 0 0.3rem 0; /* Margin'i düzenledim */

}

.card-content p {
    font-size: 1.2rem; /* Paragraf boyutunu artır */
    color: #666;
    text-align: left;
    margin: 0; /* Gereksiz margin'i kaldırdım */

}


.section-subtitle {
    color: #666;
    margin-bottom: 2rem;
}


    /* Added responsive styles */
    @media (max-width: 768px) {
        .content-wrapper {
            flex-direction: column;
        }

        .option-item {
            font-size: 1.1rem;
        }

        h3 {
            font-size: 1.6rem;
        }
    }
            `}</style>
        </>
    );
};

export default FAQ;


