import React, { useContext } from "react";

import Header from "@/components/Header/Header";
import PopupMobileMenu from "@/components/Header/PopupMobileMenu";
import Features from "@/components/Home/Features";
import LlmSlider from "@/components/Home/LlmSlider.js";
import BottomFeatures from "@/components/Home/BottomFeatures";
import FAQ from "@/components/Home/FAQ";
import DesignWorkflow from "@/components/Home/DesignWorkflow";
import MediumDesign from "@/components/Home/MediumDesign";
import MediumBottom from "@/components/Home/MediumBottom";
import ImageGeneratorShowcase from "@/components/Home/ImageGeneratorShowcase";
import ReadyToTransform from "@/components/Home/ReadyToTransform";
import Analyze from "@/components/Home/Analyze";

import Mockup from "@/components/Home/Mockup.js";
import Context from "@/context/Context";
import Circle from "@/components/Home/Circle";
import Split from "@/components/Split/Split";
import ChatBot from "@/components/ChatBot/ChatBot";
import Design from "@/components/Design/Design";
import Cookie from "@/components/Cookies/Cookies";
import Pricing from "@/components/Pricing/Pricing";
import Accordion from "@/components/Accordion/Accordion";
import Footer from "@/components/Footer/Footer";

import EvolveLink from "@/components/EvolveLink/EvolveLink";
import DesignHomeMessage from "@/components/DesignHomeMessage/DesignHomeMessage";
import DesignHomeHead from "@/components/DesignHomeHead/DesignHomeHead";
import DesignBlogsHome from "@/components/DesignBlogsHome/DesignBlogsHome";
import Maintance from  "@/components/Maintenance/Maintenance";

import PageHead from "../Head";
import { useIntl } from 'react-intl';

const HomePage = ({ handleLanguageChange }) => {
  const intl = useIntl();

  return (
    <>
   

      <PageHead title="HomePage" />

      <main className="page-wrapper">
  <Context>

   {/*  <Maintance/> */}
    <Header
      headerTransparent="header-not-transparent"
      headerSticky="header-sticky"
      btnClass="btn-small round"
      handleLanguageChange={handleLanguageChange}
    />

    <PopupMobileMenu />

    <DesignHomeMessage />
    <DesignBlogsHome />
    <Mockup/>
    <LlmSlider />
    <FAQ/>
    <Features />
    <ImageGeneratorShowcase/>

    <BottomFeatures/>
    <DesignWorkflow/>
    <Analyze />

    <MediumDesign/>

    <MediumBottom/>
    
    <ReadyToTransform/>
    <EvolveLink />
    <Footer />
    <ChatBot />
  </Context>
</main>
      <Cookie />
    </>
  );
};


export default HomePage;

/*
{
   <main className="page-wrapper">
  <Context>
    <Header
      headerTransparent="header-transparent"
      headerSticky="header-sticky"
      btnClass="btn-small round"
      handleLanguageChange={handleLanguageChange}
    />

    <PopupMobileMenu />

    <DesignHomeMessage />
    <DesignBlogsHome />
   
    <Mockup/>
    <LlmSlider />
    <Design />
    <div className="separator-animated animated-true"></div>
    <Features />
  
    <div className="separator-animated animated-true"></div>
    <Pricing />
    <Circle />
    <div className="separator-animated animated-true"></div>
    <Accordion />
    <EvolveLink />
    <Footer />
  </Context>
</main>
}
*/